import * as Sentry from '@sentry/gatsby'
import {
  fetchSignUpFromSessionStorage,
  storeSignUpInSessionStorage,
} from 'lib/storage'
import { getSignup } from 'lib/uwp'
import { useCallback, useEffect, useState } from 'react'
import { UWPSignup } from 'types/uwp'
import dummySignupObj from '../../dev/dummySignupObj.json'

type UseSignupObjectParams = {
  signUpToken?: string | null
  onError?: () => void
}

export const DUMMY_TOKEN = 'dummy-token'

export const useSignupObject = ({
  signUpToken,
  onError,
}: UseSignupObjectParams) => {
  const [signupObj, setSignupObj] = useState<UWPSignup | undefined>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const dummyDataEnabled = process.env.GATSBY_ENABLE_DUMMY_DATA === 'true'

  useEffect(() => {
    if (signUpToken && !signupObj && !error) {
      setLoading(true)
      getSignup(signUpToken)
        .then((resp) => {
          setSignupObj(resp)
          storeSignUpInSessionStorage(signUpToken, resp)
        })
        .catch((e) => {
          if (dummyDataEnabled && signUpToken === DUMMY_TOKEN) {
            // dummy data for development purposes
            setSignupObj(dummySignupObj as UWPSignup)
          } else {
            const signUpFromSessionStorage =
              fetchSignUpFromSessionStorage(signUpToken)
            if (signUpFromSessionStorage) {
              setSignupObj(signUpFromSessionStorage)
            } else {
              console.error(e)
              setError(true)
              onError && onError()
              Sentry.captureException(e)
            }
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [signUpToken, signupObj, error])

  const refetch = useCallback(() => {
    setSignupObj(undefined)
    setError(false)
  }, [])

  return {
    signupObj,
    loading,
    error,
    refetch,
  }
}
