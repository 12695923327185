import { UWPSignup } from 'types/uwp'

export const storeSignUpInSessionStorage = (
  signUpToken: string,
  signUp: UWPSignup
) => {
  sessionStorage.setItem(signUpToken, JSON.stringify(signUp))
}

export const fetchSignUpFromSessionStorage = (signUpToken: string) => {
  const jsonBlob = sessionStorage.getItem(signUpToken)
  if (jsonBlob) {
    return JSON.parse(jsonBlob) as UWPSignup
  }
  return null
}

export const removeSignUpFromSessionStorage = (signUpToken: string) => {
  sessionStorage.removeItem(signUpToken)
}
