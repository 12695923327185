import * as Sentry from '@sentry/gatsby'
import { getProgramFields } from 'lib/uwp'
import { useCallback, useEffect, useState } from 'react'
import { UWPProgramFields } from 'types/uwp'

type UseProgramFieldsParams = {
  programKey: string
  onError?: () => void
}

export const useProgramFields = ({
  programKey,
  onError,
}: UseProgramFieldsParams) => {
  const [programFields, setProgramFields] = useState<
    UWPProgramFields | undefined
  >()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (programKey && !programFields && !error) {
      setLoading(true)
      getProgramFields(programKey)
        .then((resp) => {
          setProgramFields(resp)
        })
        .catch((e) => {
          console.error(e)
          setError(true)
          onError && onError()
          Sentry.captureException(e)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [programKey, programFields, error])

  const refetch = useCallback(() => {
    setProgramFields(undefined)
    setError(false)
  }, [])

  return {
    programFields,
    loading,
    error,
    refetch,
  }
}
