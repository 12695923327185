import { UWPEnrollment, UWPProgramFields, UWPSignup } from 'types/uwp'
import { removeSignUpFromSessionStorage } from './storage'

export const getSignupCallbackURL = async ({
  partnerKey,
  programKey,
  pathParam,
  signUpToken,
}: {
  partnerKey: string
  programKey: string
  pathParam?: string | null
  signUpToken?: string | null
}) => {
  const url = new URL(
    `/utility/signup/${partnerKey}/callback/url/${programKey}`,
    process.env.GATSBY_UWP_HOST
  )
  if (pathParam) {
    url.searchParams.append('path', pathParam)
  }
  if (signUpToken) {
    url.searchParams.append('signUpToken', signUpToken)
  }

  return fetch(url.toString(), {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((resp) => {
      if (resp.ok) {
        return resp.json()
      }
      throw resp
    })
    .then((json) => {
      return json.url
    })
}

export const getSignup = async (signupToken: string): Promise<UWPSignup> => {
  const url = new URL('/utility/signup', process.env.GATSBY_UWP_HOST)
  url.searchParams.append('signUpToken', signupToken)

  return fetch(url.toString()).then((resp) => {
    if (resp.ok) {
      return resp.json()
    }
    throw resp
  })
}

export const createOrUpdateSignup = async (
  partnerKey: string,
  programKey: string,
  signupObj: UWPEnrollment,
  signUpToken?: string | null
): Promise<UWPSignup> => {
  const url = new URL('/utility/signup', process.env.GATSBY_UWP_HOST)
  url.searchParams.append('partnerKey', partnerKey)
  if (signUpToken) {
    url.searchParams.append('signUpToken', signUpToken)
  }

  return fetch(url.toString(), {
    method: 'PUT',
    body: JSON.stringify({ ...signupObj, programId: programKey }),
    headers: { 'Content-Type': 'application/json' },
  }).then((resp) => {
    if (resp.ok) {
      return resp.json()
    }
    throw resp
  })
}

export const updateSignup = async (
  signUpToken: string,
  signUpObj: UWPEnrollment,
  pathParam: string | null
) => {
  const url = new URL('/utility/signup', process.env.GATSBY_UWP_HOST)
  url.searchParams.append('signUpToken', signUpToken)
  if (pathParam) {
    url.searchParams.append('path', pathParam)
  }

  return fetch(url.toString(), {
    method: 'PATCH',
    body: JSON.stringify(signUpObj),
    headers: { 'Content-Type': 'application/json' },
  }).then((resp) => {
    if (resp.ok) {
      // remove the sign up obj from session storage, since it's now out of date
      removeSignUpFromSessionStorage(signUpToken)
      return resp.body
    }
    throw resp
  })
}

export const getProgramFields = async (
  programKey: string
): Promise<UWPProgramFields> => {
  const url = new URL(
    `/utility/signup/program/${programKey}`,
    process.env.GATSBY_UWP_HOST
  )
  return fetch(url.toString(), {
    headers: { 'Content-Type': 'application/json' },
  }).then((resp) => {
    if (resp.ok) {
      return resp.json()
    }
    throw resp
  })
}
