import React from 'react'
import { Link } from 'gatsby'
import { useProgramFields } from 'hooks/use-program-fields'
import { landingPath } from 'lib/paths'
import { useButtonStyles } from './button'

import * as styles from './start-over-error.module.css'

const StartOverError = ({
  error,
  supportEmail,
  domain,
  partnerSlug,
  programSlug,
  pathParam,
  onStartOver,
}: {
  error: string
  supportEmail?: string
  domain?: string
  partnerSlug?: string
  programSlug: string
  pathParam?: string | null
  onStartOver?: () => void
}) => {
  const { getButtonClassNames } = useButtonStyles()
  const { programFields } = useProgramFields({ programKey: programSlug })

  const contactEmail = supportEmail || programFields?.programEmail
  const errorClassNames = getButtonClassNames({
    variant: 'error',
    subvariant: 'prominent',
    nowrap: true,
  })

  return (
    <div className={styles.message}>
      <p className={styles.error}>
        {error}
        {contactEmail && (
          <>
            <br />
            Contact <a href={`mailto:${contactEmail}`}>{contactEmail}</a> for
            support.
          </>
        )}
      </p>
      {onStartOver ? (
        <button className={errorClassNames} onClick={onStartOver}>
          Start Over
        </button>
      ) : domain && partnerSlug && programSlug ? (
        <Link
          to={landingPath({
            partner: partnerSlug,
            program: programSlug,
            path: pathParam,
          })}
          className={errorClassNames}
        >
          Start Over
        </Link>
      ) : null}
    </div>
  )
}

export default StartOverError
